<script>
import Layout from "../../layouts/auth";
import appConfig from "@/app.config";

/**
 * Login component
 */
export default {
  page: {
    title: "Confirm Your Email",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      email: "",
      landing : process.env.VUE_APP_LANDING
    };
  },
  mounted() {
    // this.$ga.page('/confirmed')
    this.$ga.event(process.env.VUE_APP_GA_CONFIRMED_EMAIL, 'actionx', 'labelx', 1)
  },
  methods:{
    landingPage(){
      window.open(this.landing,"_self")
    },
  }
};
</script>

<template>
  <Layout>
    <div class="d-flex justify-content-center align-items-center confirm-signup-page">
      <div class="mx-4 px-4 left-signup">
          <img src="@/assets/images/signup.png"  alt class="cursor-pointer px-2 img-fluid" />
      </div>
      <div class="mx-4 px-4 mpx"></div>
      <div class="row justify-content-center mt-4 mb-4 pb-4 right-signup">
        <div class="mt-4">
          <div class="card overflow-hidden card-no-shadow">
            <h1 class="text-center h1-title ">Account Confirmed</h1>
            <p class="text-center px-4 font-size-16">We’re thrilled to have you on board!</p>
            <b-alert variant="success" class="mt-3 px-4 mx-4 minW" show>You’re now registered and sign in!</b-alert>
            <div class="card-body">
                <div class="pt-2 d-flex justify-content-between align-items-center">
                    <router-link tag="a" to="/login" class="btn base-btn  btn-block">
                      <span class="default-text-style">Login & Explore!</span></router-link>
                </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
          <!-- end row -->
        </div>
        <!-- end col -->
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" module></style>
